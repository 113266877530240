import { Button, Checkbox, Col, Form, Input, Result, Row, Select, Space, Spin, Typography } from "antd";
import { ethers } from "ethers";
import React, {useEffect, useState} from "react";
import { CustomCol } from "./CustomCol";
import VipList from "./VipList";
import { postForm, getVipList } from "../utils/api/productApi";
import { connect, useDispatch } from "react-redux";
import useWindowSize from "../utils/resizeHelper";
import { connect as connectWallet, signerReset } from '../redux/reducers/wallet/walletSlice';
import { NoWalletDetected } from "./NoWalletDetected";
import { translateProduct } from "../utils/translateHelper";
import moment from "moment/moment";
import {provideName} from "../utils/productNameProvider";

const { Option } = Select;
const { Title } = Typography;

const abi = `[
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "name_",
          "type": "string"
        },
        {
          "internalType": "string",
          "name": "symbol_",
          "type": "string"
        },
        {
          "internalType": "string",
          "name": "baseUri_",
          "type": "string"
        },
        {
          "internalType": "uint256",
          "name": "endOfPublicMintEpoch_",
          "type": "uint256"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [],
      "name": "ApprovalCallerNotOwnerNorApproved",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "ApprovalQueryForNonexistentToken",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "ApprovalToCurrentOwner",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "ApproveToCaller",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "BalanceQueryForZeroAddress",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "MintToZeroAddress",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "MintZeroQuantity",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "OwnerQueryForNonexistentToken",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "TransferCallerNotOwnerNorApproved",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "TransferFromIncorrectOwner",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "TransferToNonERC721ReceiverImplementer",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "TransferToZeroAddress",
      "type": "error"
    },
    {
      "inputs": [],
      "name": "URIQueryForNonexistentToken",
      "type": "error"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "approved",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "Approval",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "ApprovalForAll",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "previousOwner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnershipTransferred",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "Transfer",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address[]",
          "name": "recipients",
          "type": "address[]"
        },
        {
          "internalType": "uint64[]",
          "name": "quantities",
          "type": "uint64[]"
        }
      ],
      "name": "batchAddToWhitelist",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint32",
          "name": "amount",
          "type": "uint32"
        }
      ],
      "name": "batchMint",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "newBaseUri",
          "type": "string"
        }
      ],
      "name": "changeBaseUri",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "contractURI",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "getApproved",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getEndOfPublicMintEpoch",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getVaultAddress",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getWLSpots",
      "outputs": [
        {
          "internalType": "uint64",
          "name": "",
          "type": "uint64"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        }
      ],
      "name": "isApprovedForAll",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "maxSupply",
      "outputs": [
        {
          "internalType": "uint64",
          "name": "",
          "type": "uint64"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint32",
          "name": "amount",
          "type": "uint32"
        }
      ],
      "name": "mintToVault",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "name",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "ownerOf",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "renounceOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "safeTransferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        },
        {
          "internalType": "bytes",
          "name": "_data",
          "type": "bytes"
        }
      ],
      "name": "safeTransferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "setApprovalForAll",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "newContractMetadataUri",
          "type": "string"
        }
      ],
      "name": "setContractMetadataURI",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_newDate",
          "type": "uint256"
        }
      ],
      "name": "setEndOfPublicMintDate",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_vault",
          "type": "address"
        }
      ],
      "name": "setVaultAddress",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes4",
          "name": "interfaceId",
          "type": "bytes4"
        }
      ],
      "name": "supportsInterface",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "symbol",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "tokenURI",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenId",
          "type": "uint256"
        }
      ],
      "name": "transferFrom",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "transferOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ]`;
const ProductForm = ({ contractAddress, clearSigner, currentAddress }) => {
    const dispatch = useDispatch();
    const [signer, setSigner] = useState(null);
    const [loading, setLoading] = useState(false);
    const [wallet, setWallet] = useState(null);
    const currentSize = useWindowSize();
    const [vipList, setVipList] = useState([]);
    const [productOptions] = useState([
        { name: "Akademia SaaS", value: "Od Developera Do Foundera"},
        // { name: "Akademia Membership", value: "Akademia Membership"},
        { name: "Klub SaaS", value: "Klub SaaS"},
        { name: "Droga do 100 MLN", value: "Droga do 100 MLN"}]);
    const [inProgress, setInProgress] = useState(false);
    const [isProcessed, setIsProcessed] = useState(false);
    const [resultMessage, setResultMessage] = useState(null);
    const [resultStatus, setResultStatus] = useState("success")
    const [extraResultMessage, setExtraResultMessage] = useState("");
    const [selectedVipNft, selectVipNft] = useState(null);
    const [reloadVipList, setReloadVipList] = useState(true);
    const [loadingVipList, setLoadingVipList] = useState(false);
    const [connectedProduct, setConnectedProduct] = useState(null);
    const [changeProductAvailable, setChangeProduct] = useState(false);

    useEffect(() => {
        if(clearSigner === true) {
            setSigner(null);
            setWallet(null);
            dispatch(signerReset());
        }
    }, [clearSigner, dispatch]);

    useEffect(() => {
        if(wallet !== null && reloadVipList) {
            loadVipList(wallet);
            setReloadVipList(false);
        }
    }, [wallet, reloadVipList, setReloadVipList]);

    useEffect(() => {
        if(signer === null && currentAddress !== null) {
            handleConnect({ preventDefault: () => {} });
            loadVipList(currentAddress);
        }
    }, [currentAddress, signer]);

    const loadVipList = async (address="") => {
        setLoadingVipList(true);
        try{
            const data = await getVipList(address);
            setVipList(data);
            selectVipNft(null);
        } catch (error) {
        } finally {
            setLoadingVipList(false);
            await showNftList();
        }
    }

    const handleSubmit = async ({ name, surname, email, vipNftNo, productId, acceptPP }) => {
        setInProgress(true);
        try {
            const erc721 = new ethers.Contract(contractAddress, abi, signer);
            const ownerAddress = await erc721.ownerOf(vipNftNo);

            if (ownerAddress.toLowerCase() === wallet.toLowerCase()) {
                const messageToSign = `Confirm choose of product ${translateProduct(productId)} for VIP NFT No. ${vipNftNo}\n on given e-mail: ${email}\n FirstName: ${name}\n LastName: ${surname}\n and accept marketing approvals`;
                const signature = await signer.signMessage(messageToSign);

                const response = await postForm({ name, surname, email, vipNftNo, productId, acceptPP, signature });

                if(response.data.code === 201) {
                    setExtraResultMessage(productId === 'Klub SaaS' ? `In 7 days you will get access to ${translateProduct(productId)}`: `W ciągu najbliższych kilku godzin otrzymasz dostęp do wybranego produktu. Potwierdzenie otrzymasz osobnym mailem`);
                    setResultMessage(`Dostęp do produktu został przypisany do wybranego VIP NFT`);
                } else {
                    setResultStatus("error");
                    setResultMessage(response.data.message);
                }
                setIsProcessed(true);
            } else {
                setResultMessage(`Nie posiadasz tokenu VIP NFT o numerze.: ${vipNftNo}, upewnij się, że podłączony został prawidłowy portfel.`);
                setIsProcessed(true);
            }

        } catch (error) {
            console.log(error);
        }
        finally {
            setInProgress(false);
        }
    }

    const showNftList = async () => {
        setResultMessage(null);
        setIsProcessed(false);
        selectVipNft(null);
        setConnectedProduct(null);
        setChangeProduct(false);

        // await reloadVipNfts();
    }

    const reloadNftList = async () => {
        await loadVipList(currentAddress);
    }

    const handleConnect = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            setSigner(signer);
            setWallet(window.ethereum.selectedAddress);
            dispatch(await connectWallet(window.ethereum.selectedAddress));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleBackToVipNFTList = async (e) => {
        e.preventDefault();
        selectVipNft(null);
        setConnectedProduct(null);
        setChangeProduct(false);
    }

    const handleVipNftSelection = (item) => {
        console.log(item);
        selectVipNft(item.value);
        if(item.relatedProduct) {
            setConnectedProduct(item.relatedProduct);
            setChangeProduct(moment(item.expirationDateTime).isSameOrBefore(moment()));
        }
    }

    if(window.ethereum === undefined) {
        return (
            <Row justify="center">
                <CustomCol width={currentSize} span={12}>
                    <NoWalletDetected />
                </CustomCol>
            </Row>
        )
    }

    if(process.env.REACT_APP_MAINTENANCE === "on") {
        return <Result
            title={`Ulepszamy stronę. Prosimy o cierpliwość`}
            status={`info`}
        />
    }

    if(inProgress) {
        return (
            <Result
                title={`Podpisz wiadomość w okienku Metamask`}
                status={"info"}
            />);
    }
    if(isProcessed && resultMessage) {
        return (<Result
            status={resultStatus}
            title={resultMessage}
            subTitle={extraResultMessage}
            extra={[
                <Button key="Try again" type="primary"
                        className={"bfc-green"}
                        size={"large"}
                        block
                        onClick={reloadNftList}
                >
                    {resultStatus === 'success' ? "Przypisz kolejny produkt" : "Spróbuj ponownie"}
                </Button>
            ]}
        />)
    }

    return (
            <>
            {
                !signer && window.ethereum !== undefined && !loading && currentAddress === null &&
                <>
                    <Row justify="center">
                        <CustomCol width={currentSize}>
                            <Title>Wybierz Produkt</Title>
                        </CustomCol>
                    </Row>
                    <Row justify="center">
                        <CustomCol width={currentSize}></CustomCol>
                        <CustomCol width={currentSize} span={6}>
                            <img alt="vip card" width="100%"  src="https://bafybeiead52a4vlpzug5pxs362wwtlcpfkkwz3emo77oxcq6r2xyg62vxu.ipfs.nftstorage.link/" />
                        </CustomCol>
                        <CustomCol width={currentSize}></CustomCol>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <Button type={"primary"}
                                    className={"bfc-green"}
                                    size={"large"}
                                    block
                                    onClick={handleConnect}>
                                POŁĄCZ PORTFEL
                            </Button>
                        </Col>
                    </Row>
                </>
            }
            {
                signer &&
                (
                    <Row justify="center">
                        {
                            selectedVipNft ?
                                <CustomCol span={12} maxSpan={20} width={currentSize}>
                                    <Title level={4}>Hej! Jeśli chcesz odebrać produkt: <br/>
                                        - Akademia SaaS,<br/>
                                        - Akademia Membership,<br/>
                                        - Klub SaaS,<br/>
                                        - Droga do 100 MLN <br />
                                        Wypełnij poniższy formularz.<br/>
                                        <br/>
                                        Odbierając produkt NIE TRACISZ NFT. <br />
                                        Dostęp do produktu masz tak długo jak posiadasz NFT na tym samym portfelu. <br />
                                        Przypięty produkt możesz zmieniać co 90 dni.
                                        {/*Hey! If you would like to receive the product: <br/>
                                        - [PL] From Developer to Founder,<br/>
                                        - [PL] Membership Academy,<br/>
                                        - [PL] SaaS Club,<br/>
                                        - [PL] Road for 100 MLN<br/>
                                        Please fill out the form below.<br/>
                                        <br/>
                                        By receiving the product, you DO NOT LOSE YOUR NFT. <br/>
                                        You have access to the product as long as you possess the NFT on the same wallet. <br/>
                                        You can change the attached product every 90 days. */}
                                    </Title>
                                    <Form
                                        labelCol={{
                                            span: 4,
                                        }}
                                        wrapperCol={ {
                                            span: 8
                                        }}
                                        layout="horizontal"
                                        size={"large"}
                                        onFinish={handleSubmit}
                                    >
                                        <Form.Item label={"Vip NFT No."} name="vipNftNo" initialValue={selectedVipNft} style={{ width: "100%" }}
                                                   rules={[{
                                                       validator: (_, value) =>
                                                           value ? Promise.resolve() : Promise.reject(new Error('Wybierz VIP NFT'))
                                                   }]}
                                                   wrapperCol={{
                                                       span: 16,
                                                   }}>
                                            <Select style={{width: "100%"}} allowClear>
                                                {
                                                    vipList && vipList.map(option => {
                                                        return (<Option key={`vip-${option.value}`} value={option.value} disabled={!!option.relatedProduct}>{!!option.relatedProduct ? `${option.name} - ${provideName(option.relatedProduct)}` : option.name}</Option>)
                                                    })

                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label={"Product"} name="productId" rules={[{
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Wybierz produkt'))
                                        }]} wrapperCol={{
                                            span: 16,
                                        }}>
                                            <Select style={{width: "100%"}} allowClear>
                                                {
                                                    productOptions.map(option => {
                                                        return (<Option key={option.value} value={option.value} disabled={connectedProduct && !changeProductAvailable ? option.value !== connectedProduct : false}>{connectedProduct === option.name ? `${provideName(option.name)} - BFC VIP #${selectedVipNft}` : option.name}</Option>)
                                                    })

                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label={"Email"} name="email" rules={[{
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Podaj E-mail'))
                                        } , { type: 'email', warningOnly: true }]} wrapperCol={{
                                            span: 16,
                                        }}>
                                            <Input placeholder="E-mail"/>
                                        </Form.Item>
                                        <Form.Item label={"First name"} name="name" rules={[{
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Podaj Imię'))
                                        }]} wrapperCol={{
                                            span: 16,
                                        }}>
                                            <Input placeholder="First name"/>
                                        </Form.Item>
                                        <Form.Item label={"Lastname"} name="surname" rules={[{
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Podaj nazwisko'))
                                        }]} wrapperCol={{
                                            span: 16,
                                        }}>
                                            <Input placeholder="Lastname"/>
                                        </Form.Item>
                                        <Form.Item name="acceptPP"
                                                   rules={[{
                                                       validator: (_, value) =>
                                                           value ? Promise.resolve() : Promise.reject(new Error('Zaakceptuje politykę prywatności'))
                                                   }]}
                                                   valuePropName="checked"
                                                   wrapperCol={{
                                                       offset:4,
                                                       span: 16,
                                                   }}
                                                   label={""}
                                        >
                                            <Checkbox>
                                                Klauzula informacyjna<br />Your personal data is being processed by SOFTWARE GURU Bogusz Pękalski, located in Warsaw. Your personal data will be processed within the BFC community. For more information on the processing of personal data, including the rights you have, please refer to our privacy policy: <a href="https://www.boredfoundersclub.com/privacy-policy" target="_blank" rel="noreferrer noopener">https://www.boredfoundersclub.com/privacy-policy</a>
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item wrapperCol={{
                                            offset:4,
                                            span: 16,
                                        }}>
                                            <Space>
                                                <Button
                                                    type={"primary"}
                                                    className={"bfc-green"}
                                                    size={"large"}
                                                    block
                                                    htmlType="submit"
                                                >
                                                    Zapisz
                                                </Button>
                                                <Button type={"primary"}
                                                        className={"bfc-cancel"}
                                                        size={"large"}
                                                        block
                                                        danger
                                                        onClick={handleBackToVipNFTList}
                                                >
                                                    Wróc do listy
                                                </Button>
                                            </Space>
                                        </Form.Item>
                                    </Form>
                                </CustomCol>
                                :
                                <CustomCol span={15} maxSpan={22} width={currentSize}>
                                    <Spin spinning={loadingVipList} size="large">
                                        <VipList vipList={vipList} selectVipNft={handleVipNftSelection} currentSize={currentSize} />
                                    </Spin>
                                </CustomCol>
                        }
                    </Row>
                )
            }
        </>
    );
}
const mapStateToProps = (state) => ({
    contractAddress: state.reducers.wallet.contractAddress,
    clearSigner: state.reducers.wallet.clearSigner,
    currentAddress: state.reducers.wallet.currentAddress
});


export default connect(mapStateToProps, {})(ProductForm);